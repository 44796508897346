import {
  prepareTooltipsContainer,
  hydrateAnchorElements,
  watchForPlacementInvalidations,
  watchForNewTooltips,
  tooltipInstances,
} from './dom';

// call this function on domReady to kick everything into gear
export function initialize() {
  prepareTooltipsContainer();
  hydrateAnchorElements();
  watchForPlacementInvalidations();
  watchForNewTooltips();
}

export function findById(id) {
  return tooltipInstances.get(id) || null;
}

export function findForAnchorElement(anchor) {
  const id = anchor.dataset.tooltippedBy;

  return findById(id);
}
