import {observeDom, Observations} from './observations';
import {Tooltip} from './model';

// px padding inside the "viewport". the viewport is a box (not in the dom)
// that should sit inside the navbar and sidebar in fakturabank.
const PADDING = 20;
// space between reference element and tooltip.
const DISTANCE = 5;

// ripped from popperjs, basis for the computeOffsets function.
// I couldn't find anywhere in popperjs where they set inset directly, but I
// found an object like this, except it gets modified depending on a bunch of
// things we don't care about in fakturaBank.
// I think my browser is rewriting these as inset styles when I set them
// like this, which is weird.
const SIDE_INSETS = {
  right: {top: `0`, right: `auto`, bottom: `auto`, left: `0`},
  left: {top: `0`, right: `0`, bottom: `auto`, left: `auto`},
  top: {top: `auto`, right: `auto`, bottom: `0`, left: `0`},
  bottom: {top: `0`, right: `auto`, bottom: `auto`, left: `0`},
};

type StyleAssignment = string;
type StyleAssignments = {
  top?: StyleAssignment, right?: StyleAssignment,
  bottom?: StyleAssignment, left?: StyleAssignment,
  transform?: StyleAssignment,
  maxWidth?: StyleAssignment, maxHeight?: StyleAssignment,
};

export function updatePlacement(tooltip: Tooltip): void {
  if (!tooltip._inserted || !tooltip.tooltipElement) {
    throw new Error(`tooltip not inserted in updatePlacement()???`);
  }

  // the two-phase implementation exists because some of the information
  // we get from observeDom is incorrect unless you first position the element
  // and then observe it again.
  updatePhase1(tooltip);
  updatePhase2(tooltip);
}

function updatePhase1(tooltip: Tooltip) {
  // READ
  tooltip._observations = observeDom(tooltip);

  // COMPUTE
  const offsets = computeOffsets(tooltip._observations!);
  const sizes = computeSizeConstraints(tooltip._observations!);

  // WRITE
  const styles: StyleAssignments = {
    ...SIDE_INSETS[tooltip.placement],
    transform: `translate(${offsets.x}px, ${offsets.y}px)`,
  };

  if (sizes.width !== undefined) {
    styles.maxWidth = `${sizes.width}px`;
  }
  if (sizes.height !== undefined) {
    styles.maxHeight = `${sizes.height}px`;
  }

  Object.assign(tooltip.tooltipElement!.style, styles);
}

export type Offsets = {x: number, y: number};

function computeOffsets({
  anchorRect, tooltipRect, boundary, placement, scroll,
}: Observations): Offsets {
  function commonX() {
    return anchorRect.x + anchorRect.width / 2 - tooltipRect.width / 2 + scroll.x;
  }
  function commonY() {
    return anchorRect.y + anchorRect.height / 2 - tooltipRect.height / 2 + scroll.y;
  }

  switch (placement) {
    case `top`:
      return {
        x: commonX(),
        y: anchorRect.y - boundary.bottom + scroll.y - DISTANCE,
      };
      break;
    case `bottom`:
      return {
        x: commonX(),
        y: anchorRect.y + anchorRect.height + scroll.y + DISTANCE,
      };
      break;
    case `left`:
      return {
        x: anchorRect.x - boundary.right + scroll.x - DISTANCE,
        y: commonY(),
      };
      break;
    case `right`:
      return {
        x: anchorRect.x + anchorRect.width + scroll.x + DISTANCE,
        y: commonY(),
      };
      break;
    default:
      throw new Error(`computeOffsets placement: ${placement}`);
      break;
  }
}

type SizeConstraints = {width: number, height: number};

function computeSizeConstraints({
  anchorRect, boundary, placement
}: Observations): SizeConstraints {
  function availableWidth() {
    return boundary.right - boundary.left - (PADDING * 2);
  }
  function availableHeight() {
    return boundary.bottom - boundary.top - (PADDING * 2);
  }

  switch (placement) {
    case `top`:
      return {
        width: availableWidth(),
        height: anchorRect.top - boundary.top - DISTANCE - PADDING,
      };
      break;
    case `bottom`:
      return {
        width: availableWidth(),
        height: boundary.bottom - anchorRect.bottom - DISTANCE - PADDING,
      };
      break;
    case `left`:
      return {
        width: anchorRect.left - boundary.left - DISTANCE - PADDING,
        height: availableHeight(),
      };
      break;
    case `right`:
      return {
        width: boundary.right - anchorRect.right - DISTANCE - PADDING,
        height: availableHeight(),
      };
      break;
    default:
      throw new Error(`computeSizeConstraints placement: ${placement}`);
      break;
  }
}

function updatePhase2(tooltip: Tooltip) {
  // READ
  // getBoundingClientRect doesn't return the correct data the first time it is
  // called, hence the phase 1/2 implementation.
  tooltip._observations!.observeElementRect();

  // COMPUTE
  const offsets = computeOffsets(tooltip._observations!);
  const skidding = computeSkidding(tooltip._observations!);

  // WRITE
  const styles: StyleAssignments = {
    transform: `translate(${offsets.x + skidding.x}px, ${offsets.y + skidding.y}px)`,
  };

  Object.assign(tooltip.tooltipElement!.style, styles);
}

type Skidding = {x: number, y: number};

function computeSkidding({tooltipRect, boundary, placement}: Observations): Skidding {
  const skidding = {x: 0, y: 0};

  switch (placement) {
    case `top`:
    case `bottom`:
      const minX = boundary.left + PADDING;
      const maxX = boundary.right - tooltipRect.width - PADDING;

      if (minX > tooltipRect.left) {
        skidding.x = minX - tooltipRect.left;
      }
      if (maxX < tooltipRect.left) {
        skidding.x = maxX - tooltipRect.left;
      }

      break;
    case `left`:
    case `right`:
      const minY = boundary.top + PADDING;
      const maxY = boundary.bottom - tooltipRect.height - PADDING;

      if (minY > tooltipRect.top) {
        skidding.y = minY - tooltipRect.top;
      }
      if (maxY < tooltipRect.top) {
        skidding.y = maxY - tooltipRect.top;
      }
      break;
    default:
      throw new Error(`computeSkidding placement: ${placement}`);
      break;
  }

  return skidding;
}

//

// v DEBUG stash v

let zIndex = 83847;
const DEBUG_POINT_COLORS = [
  `magenta`, `goldenrod`, `orangered`, `deepskyblue`, `mediumseagreen`,
];

let dpointContainer: any;

// I used this function to debug placement logic. It renders a small dot at x/y
// coordinates on the in the dom. Because of the popperjs inspired implementation
// using inset (top, right, left, bottom) properties, this doesn't work
// right for all placements.
function dpoint({x, y}: any) {
  function sampleArray<T>(array: T[]): T {
    return array[Math.floor(Math.random() * array.length)];
  }

  if (!dpointContainer) {
    dpointContainer = document.createElement(`div`);
    dpointContainer.classList.add(`dpoint-container`);
    document.body.insertAdjacentElement(`afterbegin`, dpointContainer);
  }

  const el = document.createElement(`div`);
  Object.assign(el.style, {
    transform: `translate(${x}px, ${y}px)`,
    zIndex: zIndex,
    backgroundColor: sampleArray(DEBUG_POINT_COLORS),
    position: `absolute`,
    content: ``,
    width: `8px`,
    height: `8px`,
    borderRadius: `10000px`,
    marginLeft: `-4px`,
    marginTop: `-4px`,
  });
  zIndex += 1;

  dpointContainer.insertAdjacentElement(`afterbegin`, el);
}
