import {defineCustomElement} from '@/modules/custom_elements';

/*
Sometimes you have a block of html you would like to have in a modal.
Like inline editing a show form.

We are adding a local click handler on `[fb-modal-open]`.

Usage example:
```
<fb-open-modal data-modal-id="consent-for-<%= bank_account.id %>" data-title="Awesome">
  <button class="buttons-standard">
    <%= I18n.t("payments.ztl.settings.consent_refresh") %>
  </button>
</fb-open-modal>

<template id="consent-for-<%= bank_account.id %>">
  <div>
    <%= form_with url: "/payments/ztl/consent/start" do |f| %>
      <%= f.text_field :swift, value: bank_account.swift %>

      <div class="flex">
        <div class="fill-remaining-space"></div>
        <%= f.submit I18n.t("payments.ztl.settings.consent_create") %>
      </div>

    <% end %>
  </div>
</template>

<script>
  document.querySelector(`[data-modal-id="consent-for-<%= bank_account.id %>"]`)
    .addEventListener("modal-open", (event) => {
      console.log(event.detail.dom)
      // Close 3 seconds after it opened
      setTimeout(event.detail.close, 3000);
    });

  document
    .querySelector(`fb-open-modal[data-modal-id="consent-for-<%= bank_account.id %>"]`)
    .open();
</script>

```
Attributes
  Required
    modal-id     => the id to the template tag you are looking for
  Optional
    title        => Title of the modal
    width/height => width/height of modal

events
  open-modal This event it triggered when the modal is opened and the dom is rendered
             The event has some extra details
    event.details.dom
      reference to dom of the modal in case you want to hook up some events
    event.details.close is a function to close the modal

Right now it uses alertify. I would like to try to not depend on it. Maybe make something
our own or maybe use https://github.com/ghosh/Micromodal or another libary
Requirements:
  - Set initial position and size
    Popup should not open on the same spot as existing popup, a bit down and to the right.
  - Option to be movable
  - Option to be resizable
  - Option to not be closeable
  - option to have overlay, so user cant interact with the page while popup i open
      click on the overlay should close the popup.
  - shortcut(ESC) to close the popup
  - We should be able to trigger the close event from the outside

Maybe we want to use the new dialog tag
  https://www.lindsaykwardell.com/blog/native-dialog-in-elm/
  ```
  <h1>Dialogs are awesome!</h1>
  <button id="open-dialog">Open dialog</button>
  <dialog>
    <h2>My dialog</h2>
    <button>Close</button>
  </dialog>
  <script>
    document.getElementById('open-dialog').addEventListener('click', () => {
      document.querySelector('dialog').showModal();
    });

    document.querySelector('dialog > button').addEventListener('click', () => {
      document.querySelector('dialog').close();
    });
  </script>
  ```

TODO:
  Is it better to use the mutationObserver instead?
*/

// https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements
export class Modal extends HTMLElement {
  constructor() {super();}

  connectedCallback() {
    this.addEventListener(`click`, this.open);
  }

  open() {
    const options = parseOptions(this);

    const template = document.getElementById(options.id);
    const templateClone = template.content.cloneNode(true);
    const html = [...templateClone.children]
      .map(x => x.outerHTML)
      .join(``); // if we pass null/undefined we get commas between each element inside the template

    const alertifyInstance = window.alertify
      .modal(html, options.title)
      .resizeTo(options.width, options.height);

    function close() {
      alertifyInstance.close();
    }

    const openEvent = new CustomEvent(
      `modal-open`,
      {
        detail: {
          dom: alertifyInstance.elements.body,
          close,
        },
      },
    );
    this.dispatchEvent(openEvent);
  }
}

function parseOptions(modal) {
  const options = {};

  options.title = modal.dataset.title || modal.innerText || `-`;
  options.id = modal.dataset.modalId;
  if (!options.id) {
    throw new Error(`data-modal-id is required for modal`);
  }

  options.width = parseDimensionOption(modal.dataset.width, 600);
  options.height = parseDimensionOption(modal.dataset.height, 400);

  return options;
}

function parseDimensionOption(dimension, defaultValue) {
  const parsed = parseInt(dimension, 10);
  if (isNaN(parsed)) {
    return defaultValue;
  }
  return parsed;
}

export function registerComponent() {
  window.alertify.dialog(`modal`, function factory() {
    return {
      main: function(message, header) {
        this.header = header;
        this.message = message;
      },
      setup: function() {
        return {
          buttons: [],
          options: {
            resizable: true,
            movable: true,
            closable: true,
            maximizable: true,
            autoReset: false
          },
        };
      },
      prepare: function() {
        this.setHeader(this.header);
        this.setContent(this.message);
      }
    };
  });

  defineCustomElement(`fb-open-modal`, Modal);
}
