// 👀 at the dom for placement purposes

import {Tooltip, Placement} from './model';
import {ohNo} from '@/modules/oh_no';

export type Observations = {
  placement: Placement,
  tooltipRect: DOMRect,
  anchorRect: DOMRect,
  scroll: ScrollObservations,
  boundary: BoundaryObservations,
  observeElementRect: () => void,
}

type BoundaryObservations = {
  left: number, right: number, top: number, bottom: number,
}

export function observeDom(tooltip: Tooltip): Observations {
  if (!tooltip.tooltipElement) {
    throw new Error(`observeDom requires that tooltip is inserted`);
  }

  function observeElementRect() {
    if (!tooltip.tooltipElement) {
      throw new Error(`observeElementRect requires that tooltip is inserted`);
    }
    instance.tooltipRect = tooltip.tooltipElement.getBoundingClientRect();
  }

  if (!window.visualViewport) {
    ohNo(`window.visualViewport is missing (tooltips)`);
  }

  const instance: Observations = {
    placement: tooltip.placement,
    tooltipRect: tooltip.tooltipElement.getBoundingClientRect(),
    anchorRect: tooltip.anchor.getBoundingClientRect(),
    scroll: getScrollOffsets(),
    boundary: {
      left: getSidebarWidth(),
      right: window.visualViewport?.width || 1920,
      top: getNavBarHeight(),
      bottom: window.visualViewport?.height || 1080,
    },
    observeElementRect,
  };

  return instance;
}

function getSidebarWidth(): number {
  const root = document.querySelector(`:root`);
  if (!root) {
    throw new Error(`getSidebarWidth(): no :root???`);
  }

  const rootStyles = getComputedStyle(root);

  const open = window
    .localStorage.getItem(`toggle`) === `1`;

  let val;
  if (open) {
    val = rootStyles.getPropertyValue(`--fb-sidebar-open-width`);
  } else {
    val = rootStyles.getPropertyValue(`--fb-sidebar-closed-width`);
  }

  return parseInt(val.trim());
}

function getNavBarHeight(): number {
  const root = document.querySelector(`:root`);
  if (!root) {
    throw new Error(`getNavBarHeight(): no :root???`);
  }

  const rootStyles = getComputedStyle(root);
  const val = rootStyles.getPropertyValue(`--fb-navbar-height`);

  return parseInt(val.trim());
}

type ScrollObservations = {x: number, y: number}

function getScrollOffsets(): ScrollObservations {
  return {
    x: window.pageXOffset,
    y: window.pageYOffset,
  };
}
