export default class LowerCaseEmail {

  static init() {
    LowerCaseEmail.toggleEvents();
    LowerCaseEmail.bindObserver();
  }

  static input(event: JQueryEventObject) {
    $(event.currentTarget).val(`${$(event.currentTarget).val()}`.toLowerCase());
  }

  static toggleEvents(elements = $(`input[type="email"]`)) {
    $(elements).each((_index, element) => {
      if ($(element).attr(`type`) === `email`) {
        $(element).on(`change.lowerCaseEmail`, LowerCaseEmail.input);
      } else {
        $(element).off(`change.lowerCaseEmail`);
      }
    });
  }

  static bindObserver() {
    // https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver/observe#parameters
    const config = {
      attributes: true,
      childList: true,
      subtree: true,
      attributeFilter: [`type`],
    };

    // https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver/observe
    const observer = new MutationObserver(LowerCaseEmail.observerCallback);

    observer.observe($(`body`)[0], config);
  }

  static observerCallback(mutationsList: MutationRecord[]) {
    for (const mutation of mutationsList) {
      switch (mutation.type) {

        case `childList`:
          const emailInputs = (Array.from(mutation.addedNodes) as HTMLElement[])
            .filter(e => typeof e.matches == `function` && e.matches(`[type="email"]`));

          LowerCaseEmail.toggleEvents($(emailInputs));
          break;

        case `attributes`:
          LowerCaseEmail.toggleEvents($(mutation.target as HTMLElement));
          break;

        default:
          break;
      }
    }
  }
}
