import {onDocumentReady} from '@/functions/dom';
import {initialize as initTooltips} from '@/components/tooltips';
import {registerComponent as registerOpenModal} from '@/components/fb_open_modal';
import LowerCaseEmail from '@/modules/lower_case_email';

onDocumentReady(() => {
  initTooltips();
  registerOpenModal();
  LowerCaseEmail.init();
});
