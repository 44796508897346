import {ohNo} from '@/modules/oh_no';

import {tooltipsContainer, placementBuster, SHOW_EVENTS, HIDE_EVENTS} from './dom';
import {updatePlacement} from './placement';
import {Observations} from './observations';

type DelayProfile = {showDelay: number, hideDelay: number};
type DelayProfileName = `slow` | `fast` | `instant`;

// these numbers were discovered by using a slider (<input type=range>) for each
// of showDelay/hideDelay, and messing with it until it feels good.
const DELAY_PROFILES: {[key in DelayProfileName]: DelayProfile} = {
  // not very quick, makes it really easy to keep the tooltip pinned.
  slow: {showDelay: 266.5, hideDelay: 232.9},
  // fast enough to feel instant, while still allowing enough time to pin the tooltip.
  fast: {showDelay: 26.2, hideDelay: 25.2},
  // makes it virtually impossible to pin the tooltip.
  instant: {showDelay: 0, hideDelay: 0},
};

export type Placement = `left` | `right` | `bottom` | `top`;

interface TooltipOptions {
  inline: boolean,
  id: string,
  placement: Placement,
  anchor: HTMLElement,
  delayProfile: DelayProfileName,
}

type TimeoutID = ReturnType<typeof setTimeout>;
type TooltipContent = string;

export type Tooltip = {
  _firstInsertContent: TooltipContent,
  _placementBuster: number,
  _showTimeoutID?: TimeoutID,
  _hideTimeoutID?: TimeoutID,
  _observations: Observations | null,
  _inserted: boolean,
  _pinned: boolean,
  id: string,
  inline: boolean,
  placement: Placement,
  tooltipElement: HTMLElement | null,
  contentWrapper: HTMLElement | null,
  anchor: HTMLElement,
  insert: () => void,
  commandeerPrerenderedTooltip: (prerendered: HTMLElement) => void,
  setContent: (newContent: TooltipContent) => void,
  bustPlacement: () => void,
  triggerUpdate: () => void,
  isOpen: () => boolean,
  show: () => void,
  hide: () => void,
  pin: () => void,
  unpin: () => void,
} & DelayProfile

export function newTooltip(options: TooltipOptions): Tooltip {
  function insert() {
    const element = document.createElement(`div`);
    instance.tooltipElement = element;

    const contentWrapper = createContentWrapperElement();
    contentWrapper.dataset.tooltipId = instance.id;
    instance.contentWrapper = contentWrapper;

    instance._inserted = true;
    instance.setContent(instance._firstInsertContent);

    element.classList.add(`tooltip`);
    element.dataset.tooltipPlacement = instance.placement;

    element.insertAdjacentElement(`afterbegin`, contentWrapper);
    if (!tooltipsContainer) {
      ohNo(`how is tooltipsContainer undefined??`);
    } else {
      tooltipsContainer.insertAdjacentElement(`beforeend`, element);
    }
  }

  function commandeerPrerenderedTooltip(prerendered: HTMLElement) {
    instance.contentWrapper = createContentWrapperElement(prerendered);
    instance.contentWrapper.classList.remove(`tooltip`);

    instance.tooltipElement = document.createElement(`div`);
    instance.tooltipElement.classList.add(`tooltip`);

    // put the new tooltip div above the old one in the dom.
    instance.contentWrapper.insertAdjacentElement(`beforebegin`, instance.tooltipElement);
    // put the pre rendered tooltip (now the contentWrapper) inside the newly
    // created tooltipElement div. this effectively moves the contentWrapper,
    // since it cannot appear at two places in the dom at the same time.
    instance.tooltipElement.insertAdjacentElement(`afterbegin`, instance.contentWrapper);
  }

  function setContent(newContent: TooltipContent) {
    if (instance._inserted) {
      instance.contentWrapper!.innerHTML = newContent;
      // invalidate the placement cache when you change the content, since the
      // size may be different now.
      instance.bustPlacement();

      if (instance.isOpen()) {
        instance.triggerUpdate();
      }
    } else {
      instance._firstInsertContent = newContent;
    }
  }
  function bustPlacement() {
    instance._placementBuster -= 1;
  }
  function triggerUpdate() {
    // console.debug(`triggerUpdate() for ${instance.id}`);

    // console.debug(
    //   `placementBuster:`, placementBuster.get(),
    //   `tooltip._placementBuster:`, instance._placementBuster,
    // );
    if (placementBuster.get() === instance._placementBuster) {
      // console.debug(`position still valid for ${instance.id}`);
      return;
    }

    instance._placementBuster = placementBuster.get();
    // console.debug(
    //   `tooltip ${instance.id} needs placement,`,
    //   `_placementBuster updated to: ${instance._placementBuster}`,
    // );

    updatePlacement(instance);
  }

  function isOpen() {
    if (!instance.tooltipElement) return false;

    return instance.tooltipElement.classList.contains(`open`);
  }

  function show() {
    clearTimeoutSafe(instance._hideTimeoutID);

    // Delay actual work until we know the user has hovered for long enough that
    // we wanna show the tooltip. doing anything else too early is a waste of
    // resources
    instance._showTimeoutID = setTimeout(() => {
      if (!instance._inserted) {
        instance.insert();
      }
      if (!instance.tooltipElement || !instance.contentWrapper) {
        ohNo(`insertTooltip failed to add tooltipElement/contentWrapper`);
        return;
      }
      if (!instance.contentWrapper.innerHTML.trim()) return;

      instance.tooltipElement.classList.add(`open`);
      instance.triggerUpdate();

      // typescript is more cooperative with this kind of for loop
      for (const event of SHOW_EVENTS) {
        // Please read caveats in README
        instance.tooltipElement.addEventListener(event, instance.pin);
      }

      for (const event of HIDE_EVENTS) {
        instance.tooltipElement.addEventListener(event, instance.unpin);
      }

    }, instance.showDelay);
  }

  function hide() {
    clearTimeoutSafe(instance._showTimeoutID);

    instance._hideTimeoutID = setTimeout(() => {
      // prevent hiding if currently pinned. this may happen if you click on
      // stuff inside the pinned tooltip, as that may fire `blur` on the
      // anchor element.
      if (instance._pinned) return;

      // since we defer inserting elements in the dom until after user has
      // waited showDelay, you may hide it before waiting long enough
      // for it to be insterted at all.
      if (instance.tooltipElement) {
        instance.tooltipElement.classList.remove(`open`);

        for (const event of SHOW_EVENTS) {
          instance.tooltipElement.removeEventListener(event, instance.pin);
        }

        for (const event of HIDE_EVENTS) {
          instance.tooltipElement.removeEventListener(event, instance.unpin);
        }
      }

    }, instance.hideDelay);
  }

  // pin is when mouse moves into the open tooltip
  function pin() {
    clearTimeoutSafe(instance._hideTimeoutID);
    instance._pinned = true;

    if (!instance._inserted) {
      ohNo(`pin() called before _inserted: true`);
    }

    instance.tooltipElement!.classList.add(`pinned`);
  }

  // unpin is when mouse moves off the pinned tooltip
  function unpin() {
    if (!instance._inserted) {
      ohNo(`unpin() called before _inserted: true`);
    }

    instance.tooltipElement!.classList.remove(`pinned`);
    instance._pinned = false;

    instance.hide();
  }

  const instance: Tooltip = {
    // _ means internal
    _firstInsertContent: `_firstInsertContent`,
    _placementBuster: -100,
    _observations: null,
    _inserted: !options.inline,
    _pinned: false,
    // while these are options or settings the outside world may know about
    id: options.id,
    inline: options.inline,
    placement: options.placement,
    tooltipElement: null,
    contentWrapper: null,
    anchor: options.anchor,
    ...DELAY_PROFILES[options.delayProfile],
    // methods
    insert,
    commandeerPrerenderedTooltip,
    setContent,
    bustPlacement,
    triggerUpdate,
    isOpen,
    show,
    hide,
    pin,
    unpin,
  };

  return instance;
}

function createContentWrapperElement(el: HTMLElement | null = null): HTMLElement {
  if (!el) el = document.createElement(`div`);

  el.classList.add(`tooltip-content-wrapper`);
  return el;
}

function clearTimeoutSafe(id?: TimeoutID): void {
  if (id) {
    clearTimeout(id);
  }
}
